import * as React from 'react'
import styles from './styles.module.scss'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Icon, ClimateIcon } from '../Icon'
import FullScale from '../Icon/Icons/FullScale'

export interface AboutViewProps {
  props?: [{ sub_paragraph: string }]
}

const About: React.FC<AboutViewProps> = ({ props }) => {
  const { i18n } = useTranslation()

  const textParagraphs = props?.map((paragraph, i) => {
    return <p key={i}>{paragraph.sub_paragraph}</p>
  })

  return (
    <>
      <div className={styles.aboutContent}>
        <div className={styles.scaleInfoWrapper}>
          <h3>{i18n.t('about.scale')}</h3>
          <div className={styles.scaleImage}>
            <FullScale />
          </div>
          <div className={styles.scaleInfo}>
            <Icon icon={ClimateIcon} size={'large'} />
            <p>{i18n.t('about.scale_info')}</p>
          </div>
        </div>
        <div className={styles.more}>{textParagraphs}</div>

        <div className={styles.footer}>
          <p>{i18next.t('scalePage.footer')}</p>
        </div>
      </div>
    </>
  )
}

export default About
