import * as React from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../layouts'
import { get } from 'lodash'
import { SlideshowAcf } from '../models/Slideshow'
import MainContent from '../components/MainContent/MainContent'
import About from '../components/About/About'
import { Helmet } from 'react-helmet'

interface HeaderAcf extends SlideshowAcf {
  main_header?: { source_url: string; alt_text: string }
  sub_texts?: [{ sub_paragraph: string }]
  main_title: string
  text_content?: { text: string }
}
interface PageTemplateProps {
  data: {
    allWordpressPage: {
      edges: [
        {
          node: {
            acf: HeaderAcf
          }
        }
      ]
    }
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  if (!data.allWordpressPage.edges) return null

  const { main_title, main_header, sub_texts }: HeaderAcf = get(data, 'allWordpressPage.edges[0].node.acf')

  const textAbove = []

  if (sub_texts && sub_texts.length > 1) {
    const newArr = sub_texts.splice(0, 3)
    textAbove.push(newArr[0], newArr[1], newArr[2])
  }

  const meta = {
    title: 'Felix klimatskala och klimatmärkning | Klimatbutiken',
    description:
      'För att nå FN:s 1,5-gradersmål måste vi halvera utsläppen till 2030. Felix har skapat en skala som visar vilket klimatavtryck våra produkter har.'
  }

  return (
    <IndexLayout>
      <Helmet title={meta.title}>
        <meta property="og:title" content={meta.title} />
        <meta name="og:description" content={meta.description} />
        <meta name="description" content={meta.description} />
      </Helmet>
      <MainContent title={main_title} imageData={main_header} text={textAbove}>
        <About props={sub_texts} />
      </MainContent>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query ($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          acf {
            main_header {
              source_url
              alt_text
            }
            main_title
            sub_texts {
              sub_paragraph
            }
            text_content {
              text
            }
          }
        }
      }
    }
  }
`
